body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



#websitetitle {
  color: white;
  display: inline;
  padding: 10px;
  text-decoration: none;
}

.myrow {
  display: flex;
  flex-wrap: wrap; 
  /* background-color: DodgerBlue; */
}
.mycolumn {
  /* flex: 50%;
  max-width: 100%; */
  /* background-color: #f1f1f1; */
}
/* Responsive layout - makes the two columns stack on top of each other instead of next to each other
beyond a certain viewport size, check mobile/device many viewport size for best cutoff value

note, all of this is now mostly handled in javascript
*/
/* @media (max-width: 500px) {
  .mycolumn {
      flex: 100%;
      max-width: 100%;
  }
} */

.canvasbuttons {
  position:  absolute;
  top: 0;
  left: 0;  
  z-index: 100;
}